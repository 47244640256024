<template>
  <div class="layout-root">
    <div
      v-if="theme === 'edition'"
      class="background"
      @click="navigateToParentPage"
    />

    <TransitionGroup
      name="list"
      tag="div"
      class="container"
    >
      <div
        key="main"
        class="main"
      >
        <div class="main__actions">
          <ReflectSelectDateRange
            v-if="selectedYear"
            :choices="dateRangeOptions"
            :selected-choice="selectedPeriod"
            @set-choice="updateYearAndId"
          />
          <AnalyticsScopeToggle
            :is-company-only="true"
            :scope-label="selectedLegalReportFilterFormattedValue"
          />
          <div id="header-action-button" />
        </div>

        <div class="main__page">
          <slot v-if="availableLegalReports && selectedYear !== undefined" />
          <Loader v-else />
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import type { SlotsType } from 'vue'
import { type DateRangeOption } from '~/types/dateRange'
import { useCubeStore } from '~/stores/cube'
import { useLegalReportsStore } from '~/stores/legalReports'
import { cleanQueryParam, navigateToParentPage } from '~/services/router'
import { useLayoutStore } from '~/stores/layout'
import { LegalReportType } from '~/types/legalReports'
import { LEGAL_REPORTS_CONFIG_BY_ID } from '~/constants/legalReports'
import { ROUTE } from '~/constants/routes'
import { QUERY_PARAMETERS } from '~/types/queryParameters'
import { getFormattedFilterValues } from '~/services/filters'
import { getMainBdeseFilter } from '~/services/bdese'
import { useBdeseStore } from '~/stores/bdese'

export default {
  name: 'LegalReportsLayout',
  slots: Object as SlotsType<{
    default: {}
  }>,
  setup() {
    return { navigateToParentPage }
  },
  computed: {
    ...mapState(useLayoutStore, ['theme']),
    ...mapState(useLegalReportsStore, [
      'selectedYear',
      'availableLegalReports',
      'selectedLegalReportId'
    ]),
    selectedLegalReportType() {
      const route = useRoute()
      return Object.values(LegalReportType).find(legalReportType =>
        route.path.includes(LEGAL_REPORTS_CONFIG_BY_ID[legalReportType].slug)
      )
    },
    dateRangeOptions() {
      const allYears = [
        ...new Set(
          this.availableLegalReports?.map(legalReport => legalReport.year)
        )
      ]

      const usableYears = this.selectedLegalReportType
        ? allYears.filter(year =>
            useLegalReportsStore().getLegalReport(
              year,
              this.selectedLegalReportType!,
              this.getSelectedLegalReportMainFilter()
            )
          )
        : allYears

      return usableYears.map(year => ({
        label: year.toString()
      }))
    },
    selectedPeriod() {
      return { label: this.selectedYear! }
    },
    selectedLegalReportFilterFormattedValue() {
      if (this.selectedLegalReportType) {
        return getFormattedFilterValues(this.getSelectedLegalReportMainFilter())
      }
      return undefined
    }
  },
  async created() {
    const cubeStore = useCubeStore()
    await cubeStore.loadMeta()
    await useLegalReportsStore().getAvailableLegalReports()
    await this.generateSelectedLegalReportId()
  },
  async unmounted() {
    await cleanQueryParam([
      QUERY_PARAMETERS.LEGAL_REPORT_ID,
      QUERY_PARAMETERS.LEGAL_REPORT_SECTION_ID,
      QUERY_PARAMETERS.LEGAL_REPORT_YEAR
    ])
    useBdeseStore().$reset()
  },
  methods: {
    async updateYearAndId(newSelectedYear: DateRangeOption) {
      if (this.selectedLegalReportType) {
        const newLegalReport = useLegalReportsStore().getLegalReport(
          Number.parseInt(newSelectedYear.label),
          this.selectedLegalReportType!,
          this.getSelectedLegalReportMainFilter()
        )!

        await useLegalReportsStore().setSelectedLegalReportId(newLegalReport.id)
      }
      await useLegalReportsStore().setSelectedYear(newSelectedYear.label)
    },

    getSelectedLegalReportMainFilter() {
      const legalReport = this.availableLegalReports?.find(
        report => report.id === this.selectedLegalReportId
      )

      return legalReport?.filters && getMainBdeseFilter(legalReport.filters)
    },
    async generateSelectedLegalReportId() {
      const route = useRoute()

      if (this.selectedLegalReportType) {
        const legalReport = this.availableLegalReports?.find(
          legalReport =>
            legalReport.id === route.query?.id &&
            legalReport.type === this.selectedLegalReportType!
        )

        if (legalReport) {
          await useLegalReportsStore().setSelectedLegalReportId(
            route.query.id as string
          )

          await useLegalReportsStore().setSelectedYear(
            legalReport.year.toString(),
            false
          )
        } else {
          await useLegalReportsStore().setSelectedLegalReportId(null)
          await useLegalReportsStore().setSelectedYear(null)

          return navigateTo({
            path: ROUTE.LEGAL_REPORTS
          })
        }
      } else if (
        route.query.year &&
        this.availableLegalReports?.some(
          legalReport =>
            legalReport.year.toString() === (route.query.year as string)
        )
      ) {
        await useLegalReportsStore().setSelectedYear(
          route.query.year as string,
          false
        )
      } else {
        await useLegalReportsStore().setSelectedYear(null)
        await useLegalReportsStore().setSelectedLegalReportId(null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
}

.background {
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($bg-tertiary, 0.6);
  cursor: pointer;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: $page-width;
}

.main {
  @include full-viewport-height();
  position: relative;
  padding: $margin-regular 40px $margin-medium;
  width: 100%;
  display: flex;
  box-sizing: border-box;

  &__actions {
    z-index: 1;
    position: absolute;
    right: 40px;
    height: 54px;
    display: flex;
    align-items: center;
    gap: $margin-small;
  }

  &__page {
    margin-left: $side-width;
    width: calc(100% - #{$side-width});
    position: relative;
  }
}
</style>
